<template>
   <v-container fluid>
   <v-card flat>
        <v-toolbar
        color="grey darken-4"
        dark
        extended
        flat
        >
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
        </v-toolbar>

        <v-card
        class="mx-auto"
        max-width="1100"
        style="margin-top: -64px;"
        :loading="loading"
        v-if="companie"
        >
        <v-toolbar flat>
            <v-toolbar-title class="black--text">SMS Masivos</v-toolbar-title>

            <v-spacer></v-spacer>
            
            <!--<v-btn v-if="companie" :to="`/me/${companie.attributes.slug ? companie.attributes.slug : companie.id}`" color="black" class="white--text">
            <v-icon class="mr-1">mdi-open-in-new</v-icon>
            Ver Perfil público
            </v-btn>-->

            <!--<v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>-->
            <h2 v-if="companie.attributes.credits">Créditos: ${{ companie.attributes.credits.toFixed(2) }}</h2>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>

            <v-alert type="info" dense outlined dismissible>
                Descarga la plantilla para importar clientes haciendo <a href="/CustomersFalcon.xlsx" target="_blank"><strong>clic aquí</strong></a>
            </v-alert>

            <v-data-table
                loading-text="Cargando... Por favor espere"
                :headers="headers"
                :items="customers"
                :search="search"
                :header-props="{ sortIcon: null }"
                class="elevation-1"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Clientes</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="black" dark class="mr-2 d-none d-sm-flex" @click="massiveCustomerDialog = true" small> <v-icon left>mdi-database-import</v-icon> Importar clientes </v-btn>
                    <v-btn color="black" dark class="mr-2 d-none d-sm-flex" @click="dialogFilterCustomers = true" small> <v-icon left>mdi-filter</v-icon> Filtrar </v-btn>
                    <v-btn color="black" dark class="d-none d-sm-flex" @click="modalSendSms = true" small> <v-icon left>mdi-forum</v-icon> Enviar SMS masivo </v-btn>

                    <v-menu bottom right>
                        <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" class="d-print-none">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                        </template>

                        <v-list>
                        <v-list-item to="/CustomersFalcon.xlsx" target="_blank">
                            <v-list-item-title><v-icon left>mdi-file-excel</v-icon> Descargar plantilla </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="massiveCustomerDialog = true">
                            <v-list-item-title><v-icon left>mdi-database-import</v-icon> Importar clientes</v-list-item-title>
                        </v-list-item>
                         <v-list-item @click="dialogFilterCustomers = true">
                            <v-list-item-title><v-icon left>mdi-filter</v-icon> Filtrar</v-list-item-title>
                        </v-list-item>
                         <v-list-item @click="modalSendSms = true">
                            <v-list-item-title><v-icon left>mdi-forum</v-icon> Enviar SMS masivo </v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar>
                </template>

                <template v-slot:item.attributes.createdAt="{ item }">
                    {{$moment(item.attributes.createdAt).format('lll')}}
                </template>

                <template v-slot:item.attributes.status="{ item }">
                    <v-chip
                        :color="item.attributes.status == 'active' ? 'success' : 'error'"
                        class="ma-2"
                    >
                        {{item.attributes.status}}
                    </v-chip>
                    
                </template>

                <template v-slot:item.action="{ item }">
                    <v-icon
                        class="mr-2"
                        @click="showDialogTransaction(item)"
                    >
                        mdi-eye-outline
                    </v-icon>
                </template>

                <template v-slot:no-data>
                <v-btn color="primary" @click="init()">RECARGAR</v-btn>
                </template>
            </v-data-table>
           
        </v-card-text>
        </v-card>
    </v-card>


    <!-- Dialogo import customers -->
    <v-dialog
      v-model="massiveCustomerDialog"
      width="1200"
      scrollable
      persistent
    >

      <v-card>
        <v-card-title
          class="title grey lighten-3"
          primary-title
        >
          <span class="text-capitalize"> Importar clientes CSV </span>
          <v-spacer></v-spacer>
         
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
            <v-row dense>
                <v-col cols="12">
                  <vue-csv-import
                    v-model="csv"
                    url="#"
                    :map-fields="['name', 'phone', 'countryCode', 'tags']"
                    tableClass="v-data-table theme--light"
                    tableSelectClass="v-input__control h100 display-select"
                    :headers="true"
                    :autoMatchFields="true"
                    :fileMimeTypes="['text/csv']"
                  >

                    <template slot="error">
                       <p class="red--text"><strong>El tipo de archivo es invalido. </strong></p>
                    </template>
                    
                    <template slot="thead">
                        <tr style="border: 1px solid black;">
                            <th style="border: 1px solid black;">Columnas del sistema</th>
                            <th style="border: 1px solid black;">Columnas del archivo CSV</th>
                        </tr>
                    </template>

                    <template slot="next" slot-scope="{load}">
                      <v-btn
                        class="mt-5 mb-5 white--text"
                        color="black"
                        @click.prevent="load"
                      >
                      <v-icon class="mr-1">mdi-cloud-upload</v-icon>
                       Cargar archivo CSV
                      </v-btn>
                      <v-icon>mdi-numeric-1-circle-outline</v-icon>

                    <p class="mt-3 mb-3"> <strong> En la siguiente tabla, compruebe que las columnas de su archivo CSV estén en concordancia con las columnas de la base de datos. </strong></p>
                    
                    </template>

                    <template slot="submit" slot-scope="{submit}">
                        <v-btn
                          class="mt-5 mb-5 white--text"
                          color="black"
                          @click.prevent="submit"
                        >
                        <v-icon class="mr-1">mdi-table-eye</v-icon>
                          Mostrar tabla de datos
                        </v-btn>
                        <v-icon>mdi-numeric-2-circle-outline</v-icon>
                    </template>
                </vue-csv-import>
                </v-col>

                <v-col cols="12" v-if="csv.length > 0"> 
                  <v-data-table
                    :headers="headersCsv"
                    :items="csv"
                    :footer-props="pagination"
                    class="elevation-1"
                  >
                    
                    <template v-slot:no-data>
                    <v-btn color="primary" @click="init">Reset</v-btn>
                    </template>
                </v-data-table>
                </v-col>
                
            </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="massiveCustomerDialog = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-icon>mdi-numeric-3-circle-outline</v-icon>
           <v-btn
            color="success"
            outlined
            @click="importCustomers()"
          >
           <v-icon left>mdi-database-import</v-icon> Importar clientes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo import customers -->

    <!-- Dialogo filter orders -->
    <v-dialog v-model="dialogFilterCustomers" width="500" persistent v-if="companie" scrollable>
      <v-form ref="formFilter" v-model="validFilter">
        <v-card>
          <v-card-title class="title grey lighten-2" primary-title>
            <span class="text-capitalize">Filtrar Clientes</span>
          </v-card-title>

          <v-card-text>
            <div class="mb-2"></div>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                    v-model="tags_model"
                    :items="tags"
                    outlined
                    chips
                    multiple
                    label="Seleccione las etiquetas para filtrar"
                    hint="Puede seleccionar varias etiquetas para filtrar"
                    persistent-hint
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red" outlined @click="dialogFilterCustomers = false">
              <v-icon left>mdi-close</v-icon>Cerrar
            </v-btn>
            <v-spacer></v-spacer>
            
            <v-btn color="primary" dark outlined @click="init()">
              <v-icon left>mdi-autorenew</v-icon>Limpiar
            </v-btn>
            <v-btn color="success" dark outlined @click="getCustomersFilter()">
              <v-icon left>mdi-filter</v-icon>Filtrar clientes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- *End* Dialogo filter orders -->

    <!-- Dialogo Dialogo Send Call or SMS -->
    <v-dialog
      v-model="modalSendSms"
      width="500"
      persistent
      scrollable
    >
    <v-form
      ref="formcall"
      v-model="validCallSMS"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Enviar SMS Masivo </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

            <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
              <v-text-field
                    v-model="label"
                    :error-messages="labelErrors"
                    label="Etiqueta"
                    placeholder="Escriba una etiqueta EJ: Promoción 2x1 en McDonalds"
                    hint="Describe brevemente con el motivo del mensaje, para que pueda ser identificado facilmente. EJ: Promoción 2x1 en McDonalds"
                    required
                    outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                outlined
                v-model="body"
                :rules="bodyRules"
                label="Mensaje"
                placeholder="Escriba el mensaje"
                counter="160"
              ></v-textarea>
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="modalSendSms = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            outlined
            @click="sendCallSMS()"
            :disabled="body ? label ? false : true : true"
          >
           <v-icon left>mdi-send</v-icon> Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <!-- *End* Dialogo Send Call or SMS -->


   </v-container>
</template>

<script>
import Parse from 'parse';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { VueCsvImport } from 'vue-csv-import';
//import { countries } from '../plugins/allCountries'
const isPhone = (value) => /^3[0-3]\d{8}$/.test(value); 

export default {

    components:{
        VueCsvImport
    },

    mixins: [validationMixin],

    validations: {
      phone: {
        required,
        phoneValid: isPhone
      },
    },

    data() {
        return {
            companie: '',
            credential: '',
            customers: [],
            transaccion: '',
            loading: false,
            headers: [
              //{ text: 'Comercio', align: 'left', value: 'attributes.store' },
              //{ text: 'ID', align: 'left', value: 'id' },
              { text: 'Nombre',align: 'left',  value: 'attributes.name' },
              { text: 'Código País', align: 'left', value: 'attributes.countryCode' },
              { text: 'Teléfono', align: 'left', value: 'attributes.phone' },
              { text: 'Tags', align: 'center', value: 'attributes.tags' },
              { text: 'Estado', align: 'center', value: 'attributes.status' },
              { text: 'Fecha creación', align: 'left', value: 'attributes.createdAt' },
              //{ text: 'Acciones', align: 'center', value: 'action', sortable: false },
            ],
            search: '',
            label: '',
            labelErrors: [
                v => !!v || 'Etiqueta es requerido'
            ],
            bodyRules: [
                v => !!v || 'Mensaje es requerido',
                v => !!v && v.length <= 160 || 'El mensaje no debe sobrepasar los 160 caracteres'
            ],
            payload: '',
            massiveCustomerDialog: false,
            amount: '',
            modalRecharge: false,

            headersCsv: [
                {
                    text: "Nombre",
                    align: "left",
                    value: "name"
                },
                { text: "Código País", value: "countryCode", sortable: false },
                { text: "Celular", value: "phone", sortable: false },
                { text: "Tags", value: "tags", sortable: false },
                //{ text: "Fecha", value: "attributes.createdAt", sortable: false },
            ],
            csv: [],
            pagination: {
                "items-per-page-options": [50, 60, 70, 100, -1]
            },
            dialogFilterCustomers: false,
            validFilter: false,
            tags_model: [],
            tags: [],
            modalSendSms: false,
            validCallSMS: false,
            body: ''
        }
    },

    mounted() {
        this.$store.state.menuAdminActive = true
        this.init()
    },

    computed: {
        phoneErrors () {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.phoneValid && errors.push('El celular no es valido.')
            !this.$v.phone.required && errors.push('Celular es requerido.')
            return errors
        },
    },

    methods: {
        
        async init(){
            this.loading = true
            try {
                let params = {
                    id: Parse.User.current().id
                }

                let companie = await Parse.Cloud.run("getCompany", params)
                this.companie = companie
                //console.log(companie.id);
                
                this.getCustomers(companie.id)

            } catch (error) {
                this.$snotify.error(error.message , 'Empresa', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },

        async getCustomers(id){
            try {
                let params = {
                    id: id
                }

                let customers = await Parse.Cloud.run("getCustomers", params)
                this.customers = customers
                //console.log(customers);
                this.loading = false

                this.getTags(customers)

            } catch (error) {
                this.$snotify.error(error.message , 'Customers', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },

        async getCustomersFilter(){
            if (!this.companie.id || this.tags_model.length <= 0) {
                this.$snotify.error('Debes seleccionar alguna etiqueta' , 'Customers', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
                return;
            }

            try {
                let params = {
                    id: this.companie.id,
                    tags: this.tags_model
                }

                let customers = await Parse.Cloud.run("getCustomersFilter", params)
                this.customers = customers
                //console.log(customers);
                this.loading = false

                this.dialogFilterCustomers = false

            } catch (error) {
                this.$snotify.error(error.message , 'Customers Filtro', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },

        async sendCallSMS(){

            if (this.$refs.formcall.validate()) {

                let array_phones = this.customers.map(o => `${o.get("countryCode") ? o.get("countryCode") : 57}${o.get("phone")}`)
                array_phones = Array.from(new Set(array_phones))
                array_phones = array_phones.filter(Boolean).sort();

                if (array_phones.length <= 0) {
                    this.$snotify.error(`Debe enviar almenos un celular` , 'SMS Masivo', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
                }
                try {
                    let params = {
                        id: this.companie.id,
                        label: this.label,
                        phones: array_phones,
                        message: this.body
                    };
                    //console.log(params);
                    await Parse.Cloud.run("sendSMSMasive", params);
                        
                    this.$snotify.success(`SMS enviado correctamente` , 'Envío SMS', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
    
                    this.modalSendSms = false
                        
                } catch (error) {
                    this.$snotify.error(`${error}` , 'SMS Masivo', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
                }
                
            }else{
                this.$snotify.error(`Compruebe todo los campos antes de enviar el formulario` , 'SMS', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
        },

        async importCustomers(){
            if (this.csv.length > 0) {
            let params = {
                id: this.companie.id,
                customers: this.csv
            }

            await Parse.Cloud.run("importCustomers", params);
            this.init()

            this.$snotify.success(`Se han importado los clientes correctamente.` , 'Importar Clientes', {
                timeout: 4000,
                preventDuplicates: true
            });

            this.massiveCustomerDialog = false

            }else{
                this.$snotify.error(`El archivo CSV no contiene datos.` , 'Importar Clientes', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
        },

        getTags(tags){
            let array_tags = tags.map(o => o.get("tags"))
            var merged = [].concat.apply([], array_tags);
            merged = Array.from(new Set(merged))
            merged = merged.filter(Boolean).sort();
            this.tags = merged
        }

    },
}
</script>